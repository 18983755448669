@tailwind base;
@tailwind components;
@tailwind utilities;

.App-header {
  display: flex;
  flex-direction: column;
}

@font-face {
  font-family: "madani";
  src: local("madani"),
    url("../public/fonts/Madani Arabic Bold.ttf") format("truetype"),
    url("../public/fonts/madani_arabic_bold-webfont.woff") format("woff"),
    url("../public/fonts/madani_arabic_bold-webfont.woff2") format("woff2");
  font-weight: normal;
}

@font-face {
  font-family: "foa";
  src: local("foa"),
    url("../public/fonts/Aktifo-B-ExtraBold.ttf") format("truetype"),
    url("../public/fonts/Aktifo-B-ExtraBold.woff") format("woff"),
    url("../public/fonts/Aktifo-B-ExtraBold.woff2") format("woff2");
  font-weight: normal;
}
@font-face {
  font-family: "foa-book";
  src: local("foa-book"),
    url("../public/fonts/Aktifo-A-Medium.ttf") format("truetype"),
    url("../public/fonts/Aktifo-A-Medium.woff") format("woff"),
    url("../public/fonts/Aktifo-A-Medium.woff2") format("woff2");
  font-weight: normal;
}

@font-face {
  font-family: "foa-light";
  src: local("foa-light"),
    url("../public/fonts/Aktifo-A-Light.ttf") format("truetype"),
    url("../public/fonts/Aktifo-A-Light.woff") format("woff"),
    url("../public/fonts/Aktifo-A-Light.woff2") format("woff2");
  font-weight: normal;
}

.madani {
  font-family: madani, -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto",
    "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif !important;
}
.foa {
  font-family: foa, -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto",
    "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif !important;
}
.foa-book {
  font-family: foa-book, -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto",
    "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif !important;
}

.foa-light {
  font-family: foa-light, -apple-system, BlinkMacSystemFont, "Segoe UI",
    "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans",
    "Helvetica Neue", sans-serif !important;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: foa !important;
}

p {
  font-family: foa-book !important;
}
.video-docker video {
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.video-docker::after {
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.5);
  z-index: 1;
}

.video-content {
  z-index: 2;
}

.polka-2 {
  background: radial-gradient(circle, transparent 25%, #ffffff 26%),
    linear-gradient(
      45deg,
      transparent 46%,
      #f3f3f3 47%,
      #f3f3f3 52%,
      transparent 53%
    ),
    linear-gradient(
      135deg,
      transparent 46%,
      #f3f3f3 47%,
      #f3f3f3 52%,
      transparent 53%
    );
  background-size: 2em 2em;
  background-color: #ffffff;
  opacity: 1;
}

.st0 {
  fill: #ffffff;
}

/* GLOBE */
.globe {
  position: relative;
  width: 100vw;
  height: 100vh;
}

.globe .globe-list {
  z-index: 10;
  position: absolute;
  left: 0;
  top: 0;
  list-style: none;
  display: none;
}

.globe .globe-list {
  opacity: 0;
  transition: opacity 3s cubic-bezier(0.175, 0.885, 0.32, 1.275);
}

.globe .globe-list.active {
  opacity: 1;
}

.globe .globe-list > li {
  opacity: 0.4;
  position: absolute;
  margin-left: -8px;
  margin-top: -8px;
  width: 16px;
  height: 16px;
  border-radius: 50%;
  background: #00ffd3;
  transition: opacity 1s cubic-bezier(0.175, 0.885, 0.32, 1.275);
}

.globe .globe-list > li.active {
  opacity: 1;
  margin-left: -12px;
  margin-top: -12px;
  width: 24px;
  height: 24px;
}

.globe .globe-list > li:before {
  content: "";
  opacity: 0.5;
  pointer-events: none;
  position: absolute;
  left: 50%;
  top: 50%;
  margin-left: -8px;
  margin-top: -8px;
  width: 16px;
  height: 16px;
  border-radius: 50%;
  background: #00ffd3;
  -webkit-animation: 2s pulse infinite linear;
  animation: 2s pulse infinite linear;
}

@-webkit-keyframes pulse {
  0% {
    -webkit-transform: scale(1);
  }
  50% {
    opacity: 0.5;
  }
  100% {
    opacity: 0;
    -webkit-transform: scale(2);
  }
}
@keyframes pulse {
  0% {
    transform: scale(1);
  }
  50% {
    opacity: 0.5;
  }
  100% {
    opacity: 0;
    transform: scale(2);
  }
}
.globe .globe-list > li.active:before {
  margin-left: -12px;
  margin-top: -12px;
  width: 24px;
  height: 24px;
}

.globe .globe-list > li.active,
.globe .globe-list > li.active:before {
  background: #ffffff;
}

.globe .globe-list .text {
  position: absolute;
  opacity: 0.8;
  right: 21px;
  top: 50%;
  display: block;
  font-size: 14px;
  line-height: 14px;
  font-weight: 600;
  text-align: right;
  text-shadow: -1px -1px 0 #000000, 1px -1px 0 #000000, -1px 1px 0 #000000,
    1px 1px 0 #000000;
  color: #ffffff;
  white-space: nowrap;
  transform: translateY(-50%);
}

.globe .globe-list > li.active .text {
  opacity: 1;
  right: 29px;
  font-size: 20px;
  line-height: 20px;
  font-weight: 700;
}

.globe .globe-canvas {
  z-index: 1;
  position: absolute;
  left: 0;
  top: 0;
}

.globe svg {
  height: 100%;
  width: 100%;
  opacity: 0.3;
  -webkit-animation: rotation 10s linear infinite;
  animation: rotation 10s linear infinite;
}

.svg-wrapper {
  opacity: 0;
  display: inline-block;
  position: absolute;
  width: 80vh;
  height: 80%;
  left: 0;
  right: 0;
  top: 10%;
  margin: auto;
  transform: scale(0.5);
  transition: opacity 1s ease-out 1.6s, transform 1s ease-out 1.6s;
}
.svg-wrapper.active {
  opacity: 1;
  transform: none;
}

@-webkit-keyframes rotation {
  0% {
    transform: scaleX(-1) rotate(360deg);
  }
  100% {
    transform: scaleX(-1) rotate(0deg);
  }
}

@keyframes rotation {
  0% {
    transform: scaleX(-1) rotate(360deg);
  }
  100% {
    transform: scaleX(-1) rotate(0deg);
  }
}
html {
  scroll-behavior: smooth;
}
